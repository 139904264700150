
import VueTypes from 'vue-types';
export default {
  inheritAttrs: false,
  props: {
    src: VueTypes.string,
    width: VueTypes.number,
    height: VueTypes.number,
    cover: VueTypes.bool.def(false),
    // title: VueTypes.string,
    // description: VueTypes.string,
  },
  data() {
    return {
      visible: false,
      loaded: false,
      video: null,
      IntersectionObserver: null,
    };
  },
  computed: {
    videoSource() {
      return this.visible || this.loaded ? this.src : '';
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.video = this.$refs.video;
      this.video.crossOrigin = 'anonymous';
      this.initIntersectionObserver();
    });
  },
  methods: {
    onContextMenu(e) {
      e.preventDefault();
      return false;
    },
    onLoad() {
      this.loaded = true;
      this.play();
    },
    play() {
      if (this.video.paused) {
        const playPromise = this.video.play();

        // In browsers that don’t yet support this functionality,
        // playPromise won’t be defined.
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              // Automatic playback started!
            })
            .catch(() => {
              // Automatic playback failed.
              // Show a UI element to let the user manually start playback.
            });
        }
      }
    },
    pause() {
      if (!this.video.paused) {
        const pausePromise = this.video.pause();

        // In browsers that don’t yet support this functionality,
        // playPromise won’t be defined.
        if (pausePromise !== undefined) {
          pausePromise
            .then(() => {
              // Automatic playback started!
            })
            .catch(() => {
              // Automatic playback failed.
              // Show a UI element to let the user manually start playback.
            });
        }
      }
    },
    initIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '25px',
        threshold: 0,
      };

      this.IntersectionObserver = new IntersectionObserver((entries) => {
        const intersecting = !!entries[0].isIntersecting;
        this.visible = intersecting;

        if (intersecting) {
          this.play();
        } else {
          this.pause();
        }
      }, options);

      this.IntersectionObserver.observe(this.video);
    },
  },
};
